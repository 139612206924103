.movieCard {
  border: 2px solid salmon;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  width: 240px;
  height: 360px;
}

.movieGrid {
  display: flex;
  overflow-x: scroll;
}

.movieGrid .movieCard {
  flex: 0 0 300px;
  display: inline-block;
  border: 2px solid salmon;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  height: 360px;
}

.cardTopPart {
  display: flex;
}

.cardTopInformation {
  margin-left: 20px !important;
}

.cardTitle {
  font-size: 20px;
  margin-bottom: 15px;
}

.cardPoster {
  max-width: 50%;
  max-height: 50%;
  height: 240px;
}

.cardOverview {
  font-size: 14px;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
