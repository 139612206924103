.comments {
  margin-top: 40px;
  background-color: #202020;
  color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.commentForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.commentInput {
  resize: vertical;
  min-height: 100px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  border-radius: 5px;
}

.sendButton {
  align-self: flex-end;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f68e5f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sendButton:hover {
  background-color: #f9a384;
}

.commentList {
  margin-top: 20px;
}

.commentItem {
  display: flex;
  background-color: #404040;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}

.userAvatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.userInfo {
  margin-left: 10px;
}

.commentContent {
  flex-grow: 1;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.commentDate {
  align-self: flex-end;
}
