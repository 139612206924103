.myListsContainer {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.followedListsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.listItemContainer {
  width: 80%;
}

.myListsTitle {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.listAddForm {
  width: 500px;
}

.listAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
