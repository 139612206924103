.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  background-color: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 40%;
  max-width: 450px;
  text-align: center;
}

.closeButton {
  float: right;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  background: none;
  cursor: pointer;
  color: #555;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.loginButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

.loginButton:hover {
  background-color: #218838;
}

.closeModalButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #dc3545; /* Kırmızı arka plan */
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.closeModalButton:hover {
  background-color: #c82333; /* Hover durumunda daha koyu kırmızı */
}
