.addedMoviesList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.movieItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.movieNumber {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.moviePoster {
  width: auto;
  max-height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.movieInfo {
  display: flex;
  flex-direction: column;
}

.movieTitle {
  font-size: 18px;
  font-weight: bold;
}

.movieReleaseDate {
  font-size: 16px;
  color: grey;
}
