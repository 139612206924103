.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.userAvatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.userTitle {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.listsFilmsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.listsContainer,
.filmsContainer,
.usersContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ContainerTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.FilmsTitle {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.titleOption {
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.titleOption:hover {
  background-color: #f0f0f0;
}

.selectedTitle {
  color: white;
  background-color: #007bff;
}

.listItemPoster {
  width: 50px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
}

.listItemDetails {
  padding: 10px;
}
