.listCardContainer {
  padding: 2px 2px;
}

.listDetailContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  gap: 20px;
  padding: 0 30px;
}

.listCardTitle {
  color: #fe7900;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.listCardTitle:hover {
  color: #fe7900dd; /* Slightly darker orange on hover */
}

.listDescription {
  color: #aaa;
  font-size: 16px;
  flex: 1;
}

.listOwner {
  color: #aaa;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.listCreatorUser {
  color: aliceblue;
  display: flex;
  align-items: center;
  gap: 5px; /* İsim ve avatar arasındaki boşluk */
}

.userAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 5px; /* Avatarın solundaki boşluk */
}

.listCardImageGrid {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: auto;
  position: relative;
  gap: 10px;
  background-color: #393d47;
  padding: 10px;
}

.carouselItem {
  flex: 0 0 calc(26% - 8px); /* More width to each item */
  margin: 1px;
  border-bottom: 4px solid #fe7900;
  position: relative;
  transform: scale(1);
  transition: transform 0.3s;
  overflow: hidden;
  height: 360px;
}

.carouselItem:hover {
  transform: scale(1);
}

.carouselItem:hover .carouselContent {
  display: block;
}

.carouselContent {
  display: block; /* change from display: none; */
  background: rgba(
    0,
    0,
    0,
    0.7
  ); /* change from background: rgba(254, 121, 0, 0.6); */
}

.carouselThumb {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carouselContent {
  background: rgba(254, 121, 0, 0.6);
  padding: 20px 10px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  display: none;
}

.carouselTitle {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 2px;
  color: #fff;
}

.carouselRelease {
  font-size: 12px;
  color: #fff;
  margin-bottom: 7px;
}

.carouselButton {
  display: flex;
  justify-content: space-between;
}

.button {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: relative;
}

.button > span {
  font-size: 14px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: pre-wrap; /* allow the text to wrap onto the next line */
  width: 80px; /* restrict the width to 80px */
  text-align: center; /* center align the text */
  top: -45px; /* adjust the top position so that the button does not cover the text */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  pointer-events: none;
}

.button:hover > span {
  opacity: 1;
}

.leftButton {
  flex: 1;
}

.rightButton {
  flex: 1;
}

.watched {
  background-color: green;
  color: white;
}

.watched:hover {
  background-color: darkgreen;
}

.watchedContent {
  background: rgba(0, 128, 0, 0.6); /* Green background color */
}

.watchedItem {
  border-bottom: 4px solid green;
}
