.listContainer {
  display: flex;
  max-width: 100%;
  overflow: hidden;
  background-color: #393d47;
  border-radius: 10px;
  height: 100%;
}

.listTitle {
  color: #d9f5cce8;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.listSection {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.listNames {
  overflow-y: auto;
  width: 160px;
  max-height: 380px;
  padding: 10px;
  background-color: #494c55;
}

.listName {
  height: 50px;
  line-height: 50px;
  color: #fff;
  cursor: pointer;
  background-size: 100% 100%;
  background-position: 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 12px;
  box-sizing: border-box;
}

.listName:hover {
  opacity: 0.6;
}

.active {
  background-color: rgba(254, 121, 0, 0.6); /* Orange */
  background-size: 100% 100%;
  background-position: 0 0;
}

.cardContainer {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: calc(100% - 100px);
  overflow-x: hidden;
  background-color: #393d47;
  border-radius: 0 10px 10px 0;
  max-height: 100%;
}

.createListButton {
  color: #fff;
  background-color: #4caf50;
  border: none;
  padding: 10px 10px;
  margin: 10px 10px;
  cursor: pointer;
  display: block;
  width: 90%;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
}

.createListButton:hover {
  background-color: #45a049;
}

.listNames::-webkit-scrollbar {
  width: 10px; /* Scrollbar genişliği */
}

.listNames::-webkit-scrollbar-track {
  background: rgba(
    254,
    121,
    0,
    0.3
  ); /* Scrollbar arka planının sönük turuncu rengi */
}

.listNames::-webkit-scrollbar-thumb {
  background: rgba(254, 121, 0, 0.6); /* Kaydırıcı'nın turuncu rengi */
}

.listNames::-webkit-scrollbar-thumb:hover {
  background: rgba(
    254,
    121,
    0,
    0.8
  ); /* Kaydırıcıya mouseover olduğunda daha koyu turuncu renk */
}

.loginPrompt {
  display: block;
  background-color: rgba(181, 189, 193, 0.7);
  color: "green";
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .listContainer {
    flex-direction: column;
  }

  .listNames {
    width: 100%;
    height: 80px; /* Ekledik */
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .listName {
    width: auto; /* Ekledik */
    min-width: 120px; /* Ekledik, en az genişliği belirliyoruz */
    height: 50px;
    padding: 0 10px; /* Ekledik, yatay padding ekliyoruz */
  }
}

.activeIcon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4caf50;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
