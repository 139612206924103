.welcome-container {
  text-align: center;
  background-color: #191e2d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  color: #d9f5cc;
}

.features {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

.feature-item {
  position: relative;
  margin: 0 2rem; /* Daha küçük ekranlarda daha dar margin */
}

.feature-image {
  width: 16rem;
  height: 16rem;
  object-fit: cover;
  border-radius: 8px;
}

.feature-text {
  margin-bottom: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: #d9f5cc;
}

/* Küçük ekranlar için medya sorgusu */
@media screen and (max-width: 600px) {
  .feature-item {
    margin: 0 1rem; /* Daha küçük ekranlarda daha dar margin */
  }

  .feature-image {
    width: 8rem;
    height: 8rem; /* Daha küçük ekranlarda daha küçük resimler */
  }

  .feature-text {
    font-size: 1em; /* Daha küçük ekranlarda daha küçük yazı boyutu */
  }
}
