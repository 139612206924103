.infoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-auto-rows: 300px;
  grid-gap: 20px;
  overflow-x: auto;
}

.listSummaryCardTitle {
  text-decoration: none;
  color: inherit;
}

/* Yatay kaydırma çubuğunu özelleştir */
::-webkit-scrollbar {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
