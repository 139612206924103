@import "../../styles/colors.css";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  background-color: var(--theme-background);
  color: var(--theme-color);
  height: 90px;
  width: 100%;
  top: 0;
  z-index: 1;
  transition: all 0.3s ease;
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 60%;
}

.logoImage {
  height: 100px;
  width: auto;
  object-fit: contain;
}

.logoImageYazili {
  height: 60px;
  width: auto;
  object-fit: contain;
}

.menu {
  display: flex;
  gap: 1rem;
  list-style: none;
  align-items: center;
}

.menu li a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem;
}

.menu li a:hover {
  color: #ccc;
}

.btnCikis {
  border: none;
  background: none;
  color: #fff;
  /* padding: 0.5rem; */
  cursor: pointer;
  /* margin-right: 0.5rem; */
  /* flex-shrink: 0; */
}

.btnCikis:hover {
  color: #ccc;
}

.mobileMenuToggle {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.searchToggle {
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0 0.5rem;
}

.search-form {
  display: none;
}

.searchLi {
  display: flex;
  align-items: center;
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.searchContainer button {
  order: 2;
}

.searchContainer li {
  order: 1;
}

.searchInput {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 0.75rem;
  font-size: 0.8rem;
  background: #fff;
  color: #333;
  outline: none;
  width: 150%;
}

/* buton şu an gözükmüyor */
.search-button {
  border: none;
  background: none;
  color: #222;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
  padding: 0.5rem;
}

.search-button:hover {
  color: #ccc;
}

.open {
  display: flex;
}

.navLink {
  cursor: pointer;
  display: inline-block;
}

.accountMenuToggle {
  background: none;
  border: none;
  color: #fff;
  padding: 0.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0.5rem;
  position: relative;
}

.accountMenuToggle :hover {
  color: #ccc;
  display: block;
}

.navLink {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0.5rem;
  color: #fff;
}

.navLink:hover {
  color: #ccc;
}

.accountSubMenu {
  display: none;
  position: absolute;
  background-color: var(--theme-background);
  list-style: none;
  padding: 0.5rem 0;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  margin: 0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100px;
  text-align: center;
  z-index: 2;
  transition: all 0.3s ease;
}

.accountSubMenu li a {
  padding: 0.5rem 1rem;
  display: block;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
}

.accountSubMenu li a:hover {
  color: #ccc;
}

.open {
  display: block;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    padding: 0 15px;
    height: 65px;
  }

  .logo {
    flex: 1;
    margin-right: 10px;
  }

  .logoImage {
    height: 45px;
  }

  .menu {
    display: flex;
    gap: 15px;
    flex: 2;
    justify-content: flex-end;
  }

  .searchContainer {
    width: 30%;
    gap: 10px;
  }

  .searchInput {
    width: 100%;
  }

  .mobileMenuToggle {
    display: none;
  }
}

/* Telefonlar için */
@media screen and (max-width: 768px) {
  .navbar {
    height: 60px;
    padding: 0 0.25rem; /* Mobil cihazlarda daha az padding */
  }

  .logo {
    flex: 1 1 50%; /* Logoyu esnek hale getirin */
    margin-right: 0.5rem; /* Logonun sağında biraz boşluk bırakın */
    overflow: visible; /* Logonun kırpılmamasını sağlayın */
  }

  .logoImage {
    height: 40px;
    width: auto; /* Genişlik otomatik olarak hesaplanacak */
    max-width: none;
  }

  .menu {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 70%;
    left: 0;
    background: var(--theme-background);
    gap: 0.2rem;
    z-index: 2;
  }

  .mobileMenuOpen {
    display: flex;
    flex-direction: column;
  }

  .searchContainer {
    width: 100%;
    justify-content: center;
    gap: 0.5rem;
    /* flex: 1;
    margin-left: 0.5rem; */
  }

  .searchToggle {
    display: block;
    margin-left: auto;
  }

  .searchForm {
    flex: 1 1 50%;
  }

  .mobileMenuToggle {
    display: block;
  }

  .accountSubMenu {
    left: 55%;
  }
}
