.itemContainer {
  display: flex;
  align-items: center; /* merkezde hizalama için */
  margin: 10px 0;
  padding: 5px 15px;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: inherit;
  background-color: #f9f9f9; /* hafif bir arka plan rengi */
}

.itemContainer:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* gölge yoğunluğu artırıldı */
}

.movieIndex {
  font-size: 20px;
  font-weight: bold;
  margin-right: 15px;
  width: 30px;
  text-align: center; /* rakamları ortala */
  display: flex;
  align-items: center; /* dikeyde ortala */
  justify-content: center; /* yatayda ortala */
}

.listItemPoster {
  width: 20%;
  max-height: 100px; /* fotoğrafın maksimum yüksekliğini sınırla */
  object-fit: contain;
  margin-right: 15px;
  border-radius: 4px;
}

.listViewItemDetails {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* içerikleri dikeyde ortala */
}

.listViewTitle {
  font-size: 18px;
  margin-bottom: 5px;
  color: #333;
  font-weight: 600; /* başlık font ağırlığı */
}

.listViewItemDate,
.listViewMovieRate,
.movieDirector {
  font-size: 14px;
  margin-bottom: 5px;
  color: #777; /* metin renklerini daha koyu hale getir */
}

.listViewMovieRate {
  color: #ffbf00; /* puan rengi */
}
