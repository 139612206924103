:root {
  --theme-background: #1c2331;
  --light-background: #f5f5f5;
  --soft-background: #d0e6f1;
  --accent-color: #f57c00;
  --secondary-color: #c2185b;
  --contrast-color: #009688;
  --helper-color: #03a9f4;
  --helper-color-2: #7b1fa2;
}
