.userAvatarCard {
  display: flex;
  align-items: center;
  padding: 10px;
}

.userAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.userAvatar:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}
