.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.listsFilmsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.listsView,
.filmsView {
  width: 100%;
  margin: 0 auto;
}

.usersView {
  /* display: flex;
  height: 100%;
  border-top: 1px solid #ccc;
  padding-top: 5px; */
  width: 100%;
}

.mobileUsersView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trailerScroller {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

/* Ekran genişliği 600px'den büyük olduğunda uygulanacak stil */
@media screen and (min-width: 600px) {
  .homeContainer {
    flex-direction: row;
  }

  .listsFilmsContainer {
    width: 100%;
  }

  /* .usersView {
    display: block;
    flex: 0 0 15%;
    border-left: 1px solid #ccc;
    padding-left: 5px;
    width: 15%;
  } */

  .mobileUsersView {
    display: none;
  }
}

.usersViewHidden {
  display: none;
}

.toggleUsersButton {
  background-color: transparent;
  border: none;
  color: inherit; /* or choose a color that matches your theme */
  cursor: pointer;
  font-size: 24px; /* or adjust to desired size */
  padding: 10px;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.toggleUsersButton:hover {
  color: darken(inherit, 10%); /* or choose a hover color */
}
.toggleUsersButton span {
  color: inherit; /* or a specific color */
  font-size: 16px; /* or a specific size */
}
