.userCard {
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px; */
}

.userAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userName {
  margin: 0;
  color: #ffffdf;
}
