.myListsListView {
  display: block;
  margin: 20px auto;
  border: 1px solid #ccc;
  max-width: 80%; /* responsive design adjustment */
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px; /* added inner spacing for overall better look */
}

.myListsListView h2 {
  margin-top: 0;
}

.listTitle {
  font-size: 24px;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 20px; /* added more space between title and content */
  border-bottom: 1px solid #ccc; /* added line separator */
}

.listDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listFilmler {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-x: scroll;
  gap: 20px; /* increased gap for more spacing between posters */
}

.listFilmler::-webkit-scrollbar {
  height: 5px;
}

.listFilmler::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.listFilmler::-webkit-scrollbar-track {
  background-color: #eee;
}

.listTakipEdenler {
  display: inline;
  font-size: 18px; /* reduced font size to de-emphasize follower count */
  color: #777; /* changed color to a less noticeable one */
}

.listItemPoster {
  width: auto;
  height: 150px; /* increased height for better display */
  object-fit: cover;
}
