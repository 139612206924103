.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
}

.userTitle {
  font-size: 40px;
  margin-bottom: 10px;
}

.FilmsTitle {
  text-align: center;
  padding-bottom: 10px;
  padding-left: 20px;
  background-color: #c0e7fb;
  padding: 10px;
  border-radius: 5px;
}

.titleOption {
  margin: 0 20px;
  cursor: pointer;
}

.selectedTitle {
  color: red;
  border-bottom: 2px solid red;
}
.userAvatar {
  height: 150px;
  width: 120px;
  margin-right: 20px;
}

.userInfoText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.userInfoText p {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #555;
  line-height: 1.5;
}

.userInfoText p:first-child {
  margin-bottom: 5px; /* İlk metin için alttan biraz boşluk ekliyoruz */
}

.listsFilmsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(5% / 3);
  width: 100%;
}

.listsContainer {
  flex: 0 0 40%;
  max-width: 40%;
}

.filmsContainer {
  flex: 0 0 40%;
  max-width: 40%;
  max-height: 600px;
  overflow-y: auto;
}

.ContainerTitle {
  text-align: center;
  padding-bottom: 10px;
  padding-left: 20px;
}

.usersContainer {
  flex: 0 0 10%;
  max-width: 10%;
}

.movieCard {
  border: 1px solid salmon;
  border-radius: 5px;
  padding: 5px;
  margin: 20px;
  width: 240px;
  height: 360px;
}

.movieCard.accountMovieCard {
  padding: 2px;
  margin: 5px;
  width: 120px;
  height: 240px;
}

.iconButton {
  margin-left: 15px;
  font-size: 20px;
  cursor: pointer;
  color: #007bff;
}
