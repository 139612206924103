.authForm {
  max-width: 400px;
  margin: 20px auto;
  padding: 30px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.authForm h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--theme-background);
  text-align: center;
}

.label {
  display: block;
  margin-bottom: 15px;
}

.label span {
  display: block;
  margin-bottom: 5px;
  color: var(--theme-background);
  font-weight: 500;
}

.input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid var(--helper-color);
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s ease;
}

.input:focus {
  border-color: var(--accent-color);
}

.btn {
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  background-color: var(--accent-color);
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: var(--contrast-color);
}

.error {
  margin-top: 15px;
  padding: 8px;
  font-size: 14px;
  color: white;
  background-color: var(--secondary-color);
  border-radius: 6px;
}

.avatarContainer {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 15px 0;
  max-width: 500px; /* This will show 5 avatars at a time. Adjust accordingly */
}

.avatar {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.alert {
  text-align: center;
  height: 25px; /* örneğin, mesajın yüksekliğini 25px olarak ayarlayabilirsiniz */
  padding: 10px;
  margin-bottom: 10px;
  color: var(--theme-background);
  transition: color 0.3s ease;
}

.loginLink {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
}

.loginLink a {
  color: var(--accent-color);
  text-decoration: none;
}

.loginLink a:hover {
  color: var(--contrast-color);
}