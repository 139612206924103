.movie {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 20px;
  width: auto;
}

.poster {
  width: 30%;
  height: 600px;
  margin-right: 30px;
}

.title {
  font-size: 48px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.originalTitle {
  font-size: 20px;
  color: #555;
  font-weight: normal;
  margin-top: 5px;
}

.details {
  flex-grow: 1;
}

.trailer {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* For 16:9 aspect ratio */
  position: relative;
}

.trailer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 800px;
}

.info {
  flex: 1;
}

.releaseDate,
.voteAverage {
  font-size: 24px;
  margin: 10px 0;
}

.runtime {
  font-size: 22px;
  color: #555;
}

.genres {
  font-size: 16px;
  color: #555;
}

.originalLanguage {
  font-size: 16px;
  color: #555;
}

.spokenLanguages {
  font-size: 16px;
  color: #555;
}

.productionCountries {
  font-size: 16px;
  color: #555;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-left: 20px;
}

.movieButton {
  flex: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin: 0 5px;
  position: relative;
}

.movieButton.watched,
.movieButton.added {
  background-color: green;
}

.movieButton > svg {
  margin-right: 5px;
}

.movieButton > span {
  font-size: 14px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  pointer-events: none;
}

.movieButton:hover > span {
  opacity: 1;
}

.overview {
  display: flex;
  flex-wrap: wrap;
  width: 800px;
}

.director,
.castMember {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.director img,
.castMember img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
}

.castContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 200px; /* or any other value that suits your design */
  width: 100%;
  padding-right: 10px; /* Added to prevent the scrollbar from covering the content */
  width: 800px;
}

.cast {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto; /* added this line */
  white-space: nowrap; /* added this line */
  padding: 20px 0;
}

.score {
  display: flex;
  justify-content: center;
  background-color: #f1c40f;
  color: #000;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  margin: 10px 0;
  font-size: 24px;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .movie {
    flex-direction: column;
  }

  .poster {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .infoContainer {
    flex-direction: column;
  }

  .buttonGroup {
    margin-left: 0;
    margin-top: 20px;
  }
}
