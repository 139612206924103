.container {
  max-width: 100%;
  padding: 10px;
  background-color: #222831;
  overflow-x: auto;
  white-space: nowrap;
}

.header {
  font-size: 2rem;
  color: #00adb5;
  text-align: center;
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #eee;
  background-color: #393e46;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: #00adb5;
}

.activeTab {
  background-color: #00adb5;
}

.cardsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 10px;
}

/* Styling for the scrollbar for a modern look */
.container::-webkit-scrollbar {
  height: 20px;
}

.container::-webkit-scrollbar-track {
  background: #393e46; /* Scrollbar track color */
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background-color: #00adb5; /* Scrollbar color */
  border-radius: 10px;
  border: 3px solid #393e46; /* Adds some space between the scrollbar and the track */
}

/* Firefox scrollbar styling */
.container {
  scrollbar-width: thin;
  scrollbar-color: #00adb5 #393e46;
}
