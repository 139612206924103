.cardContainer {
  background-color: #f0f0f0; /* Daha aydınlık bir tema */
  color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 80%; /* Sabit yükseklik sağlamak için */
}

.moviesGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 sütunlu bir grid yapısı */
  gap: 10px; /* Öğeler arasındaki boşluğu ayarla */
  margin: 0; /* Sağda ve altta boşlukları kaldır */
}

.movieItem {
  height: 100px; /* Afiş boyutunu küçült */
  position: relative;
  background-color: #444;
  border-radius: 5px;
  overflow: hidden; /* Afişlerin dışarı taşmasını önle */
}

.movieTitleOverlay {
  position: absolute;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Başlık için daha okunaklı bir arka plan */
  color: white;
  width: 100%;
  padding: 5px;
  text-align: center;
  font-size: 12px; /* Başlık fontunu küçült */
}

.listName,
.listDescription,
.listStats {
  font-size: 16px; /* Font boyutlarını ayarla */
}

.listName {
  font-weight: bold;
}

.listDescription {
  opacity: 0.7;
}
