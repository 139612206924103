.single-movie {
  color: #666;
}
.single-movie.has-banner {
  margin-top: 30px;
}
.single-movie .entry-poster {
  float: left;
}
.single-movie .entry-info {
  display: block;
  margin-left: 230px;
}
.single-movie .entry-info h1 {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  margin-top: 0;
}
.single-movie .entry-info .pg {
  background: #fe7900;
  color: #fff;
  font-weight: bold;
  padding: 4px 8px;
  text-transform: uppercase;
}
.single-movie .entry-info .duration {
  color: #fe7900;
  font-weight: bold;
  margin-left: 15px;
}
.single-movie .entry-info ul.info-list {
  margin-top: 30px;
  padding: 0;
}
.single-movie .entry-info ul.info-list li {
  list-style: none;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
}
.single-movie .entry-info ul.info-list li label {
  color: #000;
  font-weight: bold;
  font-size: 15px;
  width: 90px;
}
.single-movie .entry-info ul.info-list li span {
  color: #666;
  font-size: 15px;
  float: right;
  width: calc(100% - 80px);
}
.single-movie .entry-media {
  position: relative;
  margin-top: 30px;
}
.single-movie .entry-media .number-media {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 13px;
  color: #999;
}
.single-movie .entry-media .number-media span.video {
  border: 1px solid #ddd;
  border-radius: 30px;
  display: inline-block;
  line-height: 26px;
  padding: 0px 18px;
}
.single-movie .entry-media .number-media span.video i {
  background: rgba(0, 0, 0, 0) url("../images/frontend/icon-media.png")
    no-repeat scroll left 2px;
  height: 13px;
  width: 13px;
  padding-right: 20px;
}
.single-movie .entry-media .number-media span.gallery {
  border: 1px solid #ddd;
  border-radius: 30px;
  display: inline-block;
  line-height: 26px;
  padding: 0px 18px;
  margin-left: 7px;
}
.single-movie .entry-media .number-media span.gallery i {
  background: rgba(0, 0, 0, 0) url("../images/frontend/icon-media.png")
    no-repeat scroll left 2px;
  height: 13px;
  width: 13px;
  padding-right: 20px;
}
.single-movie .entry-media .media-carousel {
  margin-top: 0px;
  margin-left: -10px;
  margin-right: -10px;
}
.single-movie .entry-media .media-carousel .media-item {
  padding: 0 10px;
  position: relative;
}
.single-movie .entry-media .media-carousel .media-item a:hover i {
  opacity: 1;
}
.single-movie .entry-media .media-carousel .media-item a i {
  color: #fff;
  font-size: 16px;
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 99;
  width: 40px;
  height: 40px;
  line-height: 36px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid #fff;
  margin-left: -20px;
  margin-top: -20px;
  opacity: 0.6;
}
.single-movie .entry-media .media-carousel .media-item img {
  min-height: 122px;
  width: 100%;
}
.single-movie .entry-media .media-carousel .slick-prev::before,
.single-movie .entry-media .media-carousel .slick-next::before {
  color: #ffffff;
  font-family: "FontAwesome";
  font-size: 16px;
  line-height: 1;
  opacity: 0.75;
}
.single-movie .entry-media .media-carousel .slick-next::before {
  content: "\f061";
}
.single-movie .entry-media .media-carousel .slick-prev::before {
  content: "\f060";
}
.single-movie .entry-media .media-carousel .slick-arrow {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  display: block;
  height: 30px;
  padding: 0;
  width: 30px;
}
.single-movie .entry-media .media-carousel .slick-arrow:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}
.single-movie .entry-media .media-carousel .slick-arrow.slick-next {
  right: 10px;
}
.single-movie .entry-media .media-carousel .slick-arrow.slick-prev {
  left: 10px;
}
.single-movie .entry-showtime {
  margin-top: 30px;
}
.single-movie .entry-showtime .amy-title {
  display: inline-block;
}
.single-movie .entry-showtime .select-cinema {
  background: #f8f8f8;
  width: 27%;
  float: left;
}
.single-movie .entry-showtime .select-cinema h4 {
  background: transparent url("../images/frontend/icon-cinema.png") no-repeat
    scroll 0 0;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  height: 23px;
  line-height: 24px;
  margin: 15px 25px;
  padding-left: 35px;
  text-transform: uppercase;
}
.single-movie .entry-showtime .select-cinema ul {
  margin: 0;
  padding: 20px 0;
  border-top: 2px solid #fff;
}
.single-movie .entry-showtime .select-cinema ul li {
  list-style: none;
  padding: 0 25px;
  color: #666;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 40px;
  position: relative;
  cursor: pointer;
}
.single-movie .entry-showtime .select-cinema ul li:hover,
.single-movie .entry-showtime .select-cinema ul li.active {
  background: #dbdbdb;
  color: #000;
}
.single-movie .entry-showtime .select-cinema ul li:hover:after,
.single-movie .entry-showtime .select-cinema ul li:hover:before,
.single-movie .entry-showtime .select-cinema ul li.active:after,
.single-movie .entry-showtime .select-cinema ul li.active:before {
  left: 100%;
  top: 19px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.single-movie .entry-showtime .select-cinema ul li:hover:after,
.single-movie .entry-showtime .select-cinema ul li.active:after {
  border-color: rgba(248, 248, 248, 0);
  border-left-color: #dbdbdb;
  border-width: 9px;
  margin-top: -9px;
}
.single-movie .entry-showtime .select-cinema ul li:hover:before,
.single-movie .entry-showtime .select-cinema ul li.active:before {
  border-color: rgba(248, 248, 248, 0);
  border-left-color: #dbdbdb;
  border-width: 9px;
  margin-top: -9px;
}
.single-movie .entry-showtime.single-cinema .showtime {
  width: 100%;
  float: none;
  margin: 0;
}
.single-movie .entry-showtime .showtime {
  background: #f8f8f8;
  float: left;
  margin-left: 20px;
  width: 70%;
  padding: 40px;
}
.single-movie .entry-showtime .showtime label {
  color: #000;
  font-size: 14.98px;
  font-weight: bold;
}
.single-movie .entry-showtime .showtime ul {
  padding: 0;
  margin: 0 0 25px;
}
.single-movie .entry-showtime .showtime ul li {
  background: #dbdbdb;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  padding: 3px 10px;
  margin: 0 6px 9px 0;
  list-style: none;
  display: inline-block;
}
.single-movie .entry-showtime .showtime ul li:first-child {
  margin-left: 0;
}
.single-movie .entry-action {
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  margin: 25px 0;
  padding: 10px 0;
  position: relative;
}
.single-movie .entry-action .mrate {
  display: inline-block;
  position: relative;
}
.single-movie .entry-action .mrate span.mcount {
  position: absolute;
  top: 28px;
  left: 3px;
}
.single-movie .entry-action .mrate.has-rate .movie-rating-stars {
  display: none;
}
.single-movie .entry-action .mrate .movie-rating-stars {
  background-position: left top;
  height: 18px;
  list-style: outside none none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 90px;
  margin-top: 10px;
  background-color: transparent;
  background-image: url(../images/frontend/star.png);
  background-repeat: repeat-x;
  float: left;
}
.single-movie .entry-action .mrate .movie-rating-stars a.movie-rating-star {
  border: medium none;
  height: 18px;
  left: 0;
  line-height: 18px;
  margin: 0;
  outline: medium none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-indent: -1000em;
  top: 0;
  transition: none;
}
.single-movie
  .entry-action
  .mrate
  .movie-rating-stars
  a.product-rating-star:hover,
.single-movie
  .entry-action
  .mrate
  .movie-rating-stars
  .movie-rating-stars
  a.movie-rating-star.rating-active {
  background-position: left bottom;
}
.single-movie .entry-action .mrate .movie-rating-stars a.movie-rating-star-1 {
  width: 20%;
  z-index: 6;
}
.single-movie .entry-action .mrate .movie-rating-stars a.movie-rating-star-2 {
  width: 40%;
  z-index: 5;
}
.single-movie .entry-action .mrate .movie-rating-stars a.movie-rating-star-3 {
  width: 60%;
  z-index: 4;
}
.single-movie .entry-action .mrate .movie-rating-stars a.movie-rating-star-4 {
  width: 80%;
  z-index: 3;
}
.single-movie .entry-action .mrate .movie-rating-stars a.movie-rating-star-5 {
  width: 100%;
  z-index: 2;
}
.single-movie
  .entry-action
  .mrate
  .movie-rating-stars
  a.movie-rating-star:hover,
.single-movie
  .entry-action
  .mrate
  .movie-rating-stars
  a.movie-rating-star.rating-active {
  background-position: left bottom;
}
.single-movie .entry-action .mrate .movie-rating-stars li.movie-current-rating,
.single-movie .entry-action .mrate .movie-rating-stars a.movie-rating-star {
  background-color: transparent;
  background-image: url(../images/frontend/star.png);
  background-position: left -1000px;
  background-repeat: repeat-x;
}
.single-movie .entry-action .mrate.user-rate:hover .movie-rating-stars {
  display: inline-block;
  margin-right: 15px;
}
.single-movie .entry-action .mrate.user-rate:hover ul.mv-rating-stars {
  display: none;
}
.single-movie .entry-action .rate {
  background: #ffae00;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  height: 45px;
  margin-left: 10px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  width: 45px;
}
.single-movie .entry-action .entry-share {
  float: right;
  margin-top: 16px;
}
.single-movie .entry-action .entry-share label {
  font-size: 15px;
  font-weight: bold;
  color: #000;
  float: left;
}
.single-movie .entry-action .entry-share ul {
  margin: 0;
  padding: 0;
  float: left;
}
.single-movie .entry-action .entry-share ul li {
  list-style: none;
  display: inline-block;
  padding-left: 10px;
}
.single-movie .entry-action .entry-share ul li a {
  color: #999;
}
.single-movie .entry-action .entry-share ul li a:hover {
  color: #fe7900;
}
.single-movie .entry-comment {
  margin-top: 30px;
}

.single-cinema .cinema-heading {
  margin: 40px 0 30px;
  text-align: center;
}
.single-cinema .cinema-heading .entry-content {
  color: #666;
  font-size: 15px;
}
.single-cinema .entry-title {
  color: #000;
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0;
}
.single-cinema .cinema-details {
  background: #f8f8f8;
  padding-bottom: 100px;
}
.single-cinema .cinema-details .bg-dl {
  background: #fe7900 none repeat scroll 0 0;
  display: block;
  height: 100px;
  margin: 0 auto;
  width: 50%;
}
.single-cinema .cinema-details .cinema-info {
  color: #fff;
  font-size: 15px;
  padding-top: 12px;
  text-align: center;
}
.single-cinema .cinema-movie {
  margin-top: -50px;
}
.single-cinema .cinema-movie .comment-reply-title {
  display: none;
}
.single-cinema .cinema-movie .comment-form {
  margin-top: 50px;
}
.single-cinema .cinema-movie .amy-tab-nav ul {
  border: none;
}
.single-cinema .cinema-movie .amy-tab-nav ul li a {
  border: none;
  font-size: 20px;
  color: #999;
}
.single-cinema .cinema-movie .amy-tab-nav ul li.active {
  border: none;
}
.single-cinema .cinema-movie .amy-tab-nav ul li.active a {
  color: #000;
}
@media screen and (max-width: 980px) {
  .single-cinema .cinema-movie .amy-tab-contents .amy-ajax-content {
    margin-top: 30px;
  }
}
.single-cinema .cinema-movie .amy-tab-contents .filter-mv {
  text-align: right;
}
@media screen and (max-width: 980px) {
  .single-cinema .cinema-movie .amy-tab-contents .filter-mv {
    margin-top: 40px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 980px) {
  .single-cinema
    .cinema-movie
    .amy-tab-contents
    .filter-mv.style1
    input.select-date {
    width: 31%;
    margin-right: 15px;
  }
}
@media screen and (max-width: 980px) {
  .single-cinema .cinema-movie .amy-tab-contents .filter-mv.style1 label {
    width: 31%;
    margin-right: 15px;
  }
}
.single-cinema .cinema-movie .amy-tab-contents .filter-mv label:nth-child(3) {
  display: none;
}
.single-cinema .cinema-movie .amy-tab-contents .filter-mv label:nth-child(5) {
  margin-right: 0;
}

.amy-genre {
  margin-top: 30px;
}

.amy-iframe {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.amy-iframe iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

body > .amy-iframe {
  padding: 0;
}
