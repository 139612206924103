.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: scroll;
  max-width: 100%; /* Bu değeri istediğiniz genişliğe göre ayarlayın */
}

.listSelectTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContainer > * {
  margin-right: 20px;
  margin-bottom: 20px;
}

.cardContainer > *:last-child {
  margin-right: 0;
}
