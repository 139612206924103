@import "../../styles/colors.css";

.searchResults {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--background-color);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.searchResultItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  cursor: pointer;
  background-color: var(--soft-background);
  border-radius: 4px;
  color: var(--background-color);
  transition: background-color 0.2s;
}

.searchResultItem button {
  background-color: var(--helper-color);
  border: none;
  color: var(--background-color);
  cursor: pointer;
  font-size: 1.5rem; /* larger font size */
  padding: 0.75rem 1.25rem; /* larger padding */
  border-radius: 4px;
  transition: background-color 0.2s;
}

.searchResultItem button:hover {
  background-color: var(--main-color);
}

.searchResultItem:hover {
  background-color: var(--accent-color);
  color: var(--background-color);
}

.searchResultInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.searchResultItem img {
  width: 80px;
  height: auto;
  border-radius: 4px;
  margin-right: 1rem;
}

.titleAndDate {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--main-color);
}

.releaseDate {
  color: var(--text-color);
}

/* Mobil ekranlar için */
@media (max-width: 767px) {
  .searchResults {
    width: 80%;
    transform: translateX(-50%);
    padding: 0.5rem;
    display: flex;
    overflow-x: auto;
    gap: 1rem;
  }

  .searchResultItem img {
    width: 60px;
    height: auto;
  }
}

/* Tablet ekranlar için */
@media (min-width: 768px) and (max-width: 1024px) {
  .searchResults {
    width: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    display: flex;
    overflow-x: auto;
    gap: 1rem;
  }

  .searchResultItem img {
    width: 80px;
    height: auto;
  }
}
