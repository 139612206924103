@import "./colors.css";

.authForm {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.authForm h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--theme-background);
  text-align: center;
}

.label {
  display: block;
  margin-bottom: 15px;
}

.label span {
  display: block;
  margin-bottom: 5px;
  color: var(--theme-background);
  font-weight: 500;
}

.input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid var(--helper-color);
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s ease;
}

.input:focus {
  border-color: var(--accent-color);
}

.btn {
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  background-color: var(--accent-color);
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: var(--contrast-color);
}

.error {
  margin-top: 15px;
  padding: 8px;
  font-size: 14px;
  color: white;
  background-color: var(--secondary-color);
  border-radius: 4px;
}

.signupLink {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
}

.signupLink a {
  color: var(--accent-color);
  text-decoration: none;
}

.signupLink a:hover {
  color: var(--contrast-color);
}
