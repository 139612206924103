:root {
  --gray: #757575;
  --light-gray: #bdbdbd;
  --white: #fff;
  --light-blue: #90caf9;
  --dark-blue: #0d47a1;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  background-color: var(--light-gray);
}

.form {
  width: 100%;
  /* max-width: 700px; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: var(--white);
  border-radius: 4px;
  padding: 2rem;
  color: var(--gray);
}

.formLabel {
  display: block;
}

.labelText {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--gray);
}

.nameInput,
.descInput,
.searchInput {
  font-size: 1rem;
  padding: 0.75rem;
  border: 1px solid var(--gray);
  border-radius: 4px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--white);
  color: var(--gray);
}

.nameInput:focus,
.descInput:focus,
.searchInput:focus {
  border-color: var(--dark-blue);
  box-shadow: 0 0 0 0.2rem var(--light-blue);
}

.descInput {
  min-height: 60px;
  resize: vertical;
}

.searchContainer {
  position: relative;
}

.searchForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.createButton,
.closeButton {
  font-size: 1rem;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  max-width: 200px;
  color: #fff;
}

.createButton {
  background-color: var(--light-blue);
}

.createButton:hover {
  background-color: var(--dark-blue);
}

.closeButton {
  background-color: var(--gray);
}

.closeButton:hover {
  background-color: var(--light-gray);
}

.moviePostersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.visibilityContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
}

.visibilityOptions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}

.visibilityOption,
.visibilityOptionActive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  color: var(--gray);
}

/* .visibilityOptionActive {
  background: var(--light-blue);
} */

.visibilityIcon {
  font-size: 1.5rem;
  color: var(--light-blue);
}

.visibilityOptionActive .visibilityIcon {
  color: var(--dark-blue);
}

.moviePoster {
  flex: 0 1 calc(33.33% - 1rem);
  border: 1px solid var(--gray);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moviePoster img {
  width: 100%;
  height: auto;
}
