.eventsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.eventsTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.eventList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
}

.eventItem {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}

.userAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
