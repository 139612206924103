.header {
  font-size: 2em; /* Başlık font boyutunu büyüt */
  text-align: center;
  color: #fff; /* Başlık rengini beyaza çevir */
  background-color: #333; /* Başlık için koyu arka plan */
  padding: 10px;
  margin-bottom: 20px; /* Alt marjı arttır */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Başlık için gölge efekti */
}
.commentsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px;
  background: linear-gradient(to right, #dae2f8, #d6a4a4);
}

.comment {
  display: flex;
  width: calc(50% - 10px);
  margin-bottom: 10px;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.moviePoster {
  width: 120px; /* Afiş boyutunu ayarla */
  height: 180px;
  object-fit: cover;
  border-radius: 5px;
}

.userAvatar {
  flex-basis: 80px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #ddd;
}

.userAvatar img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.commentDetails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
}

.infoColumn {
  display: flex;
  flex-direction: column;
}

.movieInfo {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.movieTitle {
  font-weight: bold;
  color: #333;
  font-size: 1em;
  margin-left: 10px; /* Film başlığı için sola boşluk ekle */
}

.movieComment {
  font-size: 0.9em;
  line-height: 1.4;
  color: #333;
  overflow: hidden;
  margin-left: 0.6rem;
  margin-top: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.commentContent {
  order: 2; /* Yorum içeriğini alt kısma taşı */
  max-width: 100%;
  flex-grow: 1;
}

.commentContent p {
  font-size: 0.9em;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.timestamp {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.8em;
  color: #666;
}

.userName {
  font-size: 0.85em;
  color: #333;
  margin-top: 5px; /* Kullanıcı ismi için üstte boşluk ekle */
}
