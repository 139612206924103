.listCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-bottom: 10px;
}

.listCardTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.listCardImageGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.listCardImageGrid .listCardPoster {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.listCardPosterContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.listCardPosterContainer .listCardPoster {
  width: calc(50% - 5px);
  margin-bottom: 10px;
}
