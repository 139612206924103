.listInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
}

.listTitle {
  font-size: 36px;
  margin-bottom: 5px;
  margin-left: 20px;
  font-weight: 700;
  font-family: "Arial", sans-serif;
}

.listDescription {
  font-size: 18px;
  color: #333;
  margin-left: 20px;
  margin-bottom: 0px;
  /* padding: 10px; */
  max-width: 600px;
  background-color: #f5f5f5;
  font-family: "Arial", sans-serif;
}

.movieCountInfo {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
  margin-top: 20px;
  margin-right: 200px;
  font-weight: 600;
  color: #333;
}

.movieCountInfo span.dynamic {
  color: #007bff;
  font-weight: bold;
}

.creatorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.listCreatorUser {
  display: flex;
  align-items: center;
}

.listCreatorUser > span {
  margin-right: 10px;
}

.followersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.listViewContainer {
  padding: 0px 100px;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.listAddButton {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 20px;
}

.listAddButton.added {
  background-color: green;
  color: white;
  border-color: darkgreen;
}

.listAddButton > svg {
  margin-right: 5px;
}

.listAddButton > span {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  pointer-events: none;
}

.listAddButton:hover > span {
  opacity: 1;
}

.listAddButton:hover {
  background-color: #f5f5f5;
  border-color: #999;
  color: #333;
}

.searchContainer {
  position: relative;
  margin-bottom: 10px;
  margin-left: 20px;
  max-width: 600px;
}

.searchForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelText {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--gray);
}

.nameInput,
.descInput,
.searchInput {
  font-size: 1rem;
  padding: 0.75rem;
  border: 1px solid var(--gray);
  border-radius: 4px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--white);
  color: var(--gray);
}

.nameInput:focus,
.descInput:focus,
.searchInput:focus {
  border-color: var(--dark-blue);
  box-shadow: 0 0 0 0.2rem var(--light-blue);
}

.movieIndex {
  font-size: 1.5em;
  color: #333;
  margin-right: 10px;
}

.itemNumber {
  font-size: 2em;
  color: #333;
  font-weight: 700;
  text-align: center;
  margin-right: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listViewItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.movieDetails {
  width: calc(100% - 50px);
}

.sortContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.sortButton {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  color: #222222;
  display: flex;
  align-items: center;
  padding: 10px;
}

.sortButton span {
  margin-right: 10px;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
}

.sortButton select {
  border: none;
  background: transparent;
  color: #333333;
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  .listViewContainer {
    padding: 0 10px;
  }

  .listViewItem {
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    margin-bottom: 5px;
  }

  .movieDetails {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 5px;
  }

  .itemNumber {
    min-width: 30px;
    margin-right: 5px;
  }

  .sortButton {
    width: 100%;
    justify-content: space-between;
  }

  .listAddButton,
  .listAddButton > span {
    font-size: 14px;
  }
}
