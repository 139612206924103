.trailer_scroller {
  font-family: Arial, sans-serif;
  background-color: #232323;
  padding: 5px;
  color: white;
}

h2 {
  margin-bottom: 10px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 30px;
}

.buttonGroup {
  display: flex;
  gap: 10px; /* Bu, butonlar arasındaki boşluğu ayarlar. */
}

.buttonGroup button {
  background: none;
  border: none;
  color: #ff8c00; /* Turuncu renk */
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;
}

.buttonGroup button:hover {
  color: #ffad4d; /* Açık turuncu renk */
}

.scroller_wrap {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 10px;
}

.card {
  display: inline-block;
  margin: 10px;
  background: #2c2c2c;
  border-radius: 10px;
  padding: 10px;
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

.card img {
  width: 320px;
  height: 180px;
  border-radius: 7px;
}

.card h3 {
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.card p {
  white-space: normal;
  font-size: 0.9em;
  opacity: 0.8;
  width: 340px;
  overflow-wrap: break-word;
  text-align: center;
  margin-bottom: 5px;
}

.trailer_link {
  position: relative;
  display: inline-block;
}

.play_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 1200px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 15px;
  z-index: 1000;
  padding: 30px;
}

.modal iframe {
  width: 100%;
  height: 60vw;
  max-height: 600px;
  border-radius: 10px;
}

.modal button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.linkStyle {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
