.itemContainer {
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2; /* isteğe bağlı */
  border-radius: 10px; /* köşeleri yumuşatır */
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  max-height: 600px;
  overflow-y: auto;
}

.itemContainer:hover {
  transform: scale(1.05); /* hover durumunda %5 büyür */
}

.listViewItemContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.listItemPoster {
  width: 30%; /* Adjust this value as required */
  border-radius: 5px; /* isteğe bağlı */
}

.listViewItemDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 45%; /* Adjust this value as required */
  padding-left: 10px;
}

.listViewTitle {
  font-size: 26px;
  color: #333; /* isteğe bağlı */
}

.listViewItemDate {
  font-size: 18px; /* isteğe bağlı */
  color: #777; /* isteğe bağlı */
}

.listViewMovieRate {
  font-size: 24px; /* isteğe bağlı */
  color: #333; /* isteğe bağlı */
}
