.listCreateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.formWrapper {
  width: 100%;
  max-width: 900px;
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
