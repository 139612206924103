.detailedUserCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #34495e;
  border-radius: 5px;
  padding: 20px;
  margin: 15px;
  width: 200px;
  background-color: #34495e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.detailedUserCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.userAvatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 3px solid #f39c12;
  transition: border-color 0.2s;
}

.detailedUserCard:hover .userAvatar {
  border-color: #e67e22;
}

.userName {
  font-weight: bold;
  margin-bottom: 10px;
  color: #ecf0f1;
  transition: color 0.2s;
}

.detailedUserCard:hover .userName {
  color: #f39c12;
}

.detailedUserCard p {
  font-size: 14px;
  color: #bdc3c7;
  margin-bottom: 5px;
}

.detailedUserCard .label {
  font-weight: bold;
  color: #ecf0f1;
}

.detailedUserCard .value {
  font-weight: normal;
  color: #f39c12;
}
