.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  overflow: hidden;
  padding: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
  width: 100%;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  background-color: #f7f7f7;
  border-radius: 0.5rem;
}

.userDetails {
  display: flex;
  flex-direction: column;
}

.userTitle {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.userAvatar {
  height: 150px;
  width: 120px;
  object-fit: cover;
  border-radius: 0.5rem;
}

.AccountRecentActivities {
  flex: 0 0 40%; /* it takes 40% of its parent's width */
  max-width: 40%;
  overflow: hidden; /* prevent any potential overflow */
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 0.5rem;
}

.listsFilmsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(5% / 3);
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
}

.listsContainer,
.filmsContainer {
  flex: 0 0 30%;
  max-width: 30%;
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 0.5rem;
  max-height: 600px;
  overflow-y: auto;
}

.ContainerTitle {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.movieCard {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  width: 240px;
  height: 360px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.movieCard.accountMovieCard {
  padding: 0.5rem;
  margin: 0.5rem;
  width: 120px;
  height: 180px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.FilmsTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.titleOption {
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.titleOption:hover {
  color: #007bff;
}

.selectedTitle {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.moviesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
}

.movieCard {
  border: 1px solid salmon;
  border-radius: 5px;
  padding: 5px;
  margin: 20px;
  width: 240px;
  height: 360px;
}

.movieCard.accountMovieCard {
  padding: 2px;
  margin: 5px;
  width: 120px;
  height: 240px;
}

.moviesContainer,
.listsContainer > div {
  justify-content: flex-start;
  align-items: flex-start;
}
