.listViewItem {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  text-decoration: none;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.listViewItemContent {
  display: flex;
  flex: 1;
  align-items: center;
}

.listViewItem a {
  text-decoration: none;
}

.listItemPoster {
  width: 100px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 4px;
}

.listViewItemDetails {
  flex: 1;
}

.listViewTitle {
  font-size: 28px;
  color: #333;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listViewItem p {
  margin: 0;
}

.originalTitle {
  font-size: 20px;
  color: #555;
  font-style: italic;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.releaseDate {
  font-size: 18px;
  margin-bottom: 5px;
  color: #757575;
}

.voteAverage {
  font-size: 24px;
  margin-top: 10px;
  color: #333;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-left: auto;
}

.button {
  flex: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 2.5px;
  position: relative;
}

.button.watched {
  background-color: #4caf50;
}

.button.added {
  background-color: #007bff;
}

.button.addedToWatchlist {
  background-color: #007bff;
}

.button > svg {
  margin-right: 5px;
}

.button > span {
  font-size: 16px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  pointer-events: none;
}

.button:hover > span {
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .listViewItem {
    flex-direction: row;
    align-items: center;
    padding: 15px;
    margin-bottom: 15px;
    max-height: none;
  }

  .listViewItemContent {
    flex-direction: row;
    align-items: center;
  }

  .listItemPoster {
    width: 80px;
    height: 120px;
    margin-right: 15px;
  }

  .listViewItemDetails {
    flex: 1;
  }

  .listViewTitle {
    font-size: 18px;
  }

  .originalTitle {
    font-size: 16px;
  }

  .releaseDate,
  .voteAverage {
    font-size: 14px;
  }

  .buttonGroup {
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
  }

  .button {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .button > span {
    top: 40px;
  }
}
