.activitiesContainer {
  padding: 20px;
  border-radius: 8px;
  margin-top: 10px;
  background-color: #f9fafb;
  max-height: 600px;
  overflow-y: auto;
}

.activityList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.activityItem {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  transition: all 0.2s;
}

.activityItem:hover {
  background-color: #f5f5f5;
}

.userAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.moviePoster {
  width: 60px;
  height: auto;
  border-radius: 8px;
}

.activityItem p {
  margin: 0;
  font-size: 1em;
  color: #8e44ad; /* Mor renk tonu, aktivite (eylem) metni için */
}

.activityItem p span.movieTitle {
  font-weight: 600;
  color: #2c3e50; /* Koyu mavi ton, filmin adı için */
}

.activityItem p span.unimportant {
  color: #7f8c8d; /* Gri ton */
  font-weight: 400;
}

/* Tarih için stil */
.activityItem p span.date {
  color: #3498db; /* Açık mavi ton */
  font-weight: 500;
  margin: 0 5px;
}
