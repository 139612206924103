.cardContainer {
  background-color: #1c1e21;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 5px;
  width: 250px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.cardContainer:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.listName {
  font-size: 1.24rem;
  font-weight: bold;
  padding: 2px;
  text-align: center;
  background-color: #272b30;
  border-bottom: 1px solid #343a40;
}

.listStats {
  font-size: 0.9rem;
  padding: 2px;
  text-align: center;
  background-color: #343a40; /* Slightly different background for contrast */
  color: #c0c2c5;
}

.listDescription {
  font-size: 0.9rem;
  padding: 4px;
  text-align: center;
  background-color: #272b30;
  color: #868e96;
}

.moviesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 8px;
}

.movieItem {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.moviePoster {
  width: 100%;
  height: auto;
  display: block;
  transition: opacity 0.3s ease;
}

.movieTitleOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}

.movieItem:hover .movieTitleOverlay {
  opacity: 1;
}

.movieTitleOverlay span {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 100%;
}
