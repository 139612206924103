.homeUsersViewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #29303b;
}

.homeUsersViewTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #a1bde8;
}

.usersList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
}
