.account-list-view {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  min-width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background-color: #f9f9f9;
  max-height: 600px;
  overflow-y: auto;
}

.account-list-view h2 {
  margin-top: 0;
}

.list-title {
  text-align: center;
  padding-bottom: 5px;
}

.list-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.list-filmler {
  display: flex;
  overflow-x: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: 20px !important;
  justify-content: space-around;
  list-style-type: no;
}

.list-filmler li {
  display: flex;
  margin: 0.5em;
}

.listItemPoster {
  width: auto;
  max-height: 100px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  position: relative;
}

.listItemPoster::after {
  content: attr(alt);
  position: absolute;
  background-color: #f9f9f9;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  bottom: 150%; /* tooltip'in konumunu ayarlar */
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.listItemPoster:hover::after {
  visibility: visible;
  opacity: 1;
}

.list-takip-edenler {
  display: inline;
  text-align: center;
  margin-top: 10px;
  width: 100%;
}
