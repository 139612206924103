.usersContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #2c3e50;
  text-align: center;
}

.usersTitle {
  font-size: 32px;
  color: #ecf0f1;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid #f39c12;
  padding-bottom: 10px;
  display: inline-block;
}

.usersCount {
  font-size: 24px;
  color: #f39c12;
  margin-left: 10px;
}

.usersList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.detailedUserCard .label {
  font-weight: bold;
  color: #ecf0f1;
}

.detailedUserCard .value {
  font-weight: normal;
  color: #f39c12;
}

@media screen and (max-width: 768px) {
  .usersList {
    flex-direction: column;
    align-items: center;
  }
}

.detailedUserCard p {
  font-size: 14px;
  color: #bdc3c7;
  margin-bottom: 5px;
}

.detailedUserCard .label {
  font-weight: bold;
  color: #ecf0f1;
}

.detailedUserCard .value {
  font-weight: normal;
  color: #f39c12;
}
