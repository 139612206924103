/* global.css */
@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");

/* Genel Stiller */
:root {
  --main-color: #0078d4;
  --secondary-color: #3c3c3c;
  --background-color: #f5f5f5;
  --text-color: #333333;
  --font-family: "Roboto", sans-serif;
  --font-size-base: 16px;
  --line-height-base: 1.5;
  --spacing-unit: 1rem;
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  background-color: var(--background-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Layout */
.container {
  margin: 0 auto;
  padding: 0 var(--spacing-unit);
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: var(--spacing-unit);
}

p {
  margin-top: 0;
  margin-bottom: var(--spacing-unit);
}

ul {
  padding-inline-start: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Buttons */
.button {
  display: inline-block;
  background-color: var(--main-color);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: darken(var(--main-color), 10%);
}

/* Listeler */
.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  padding: var(--spacing-unit) 0;
  border-bottom: 1px solid var(--secondary-color);
}

.list-item:last-child {
  border-bottom: none;
}

/* Chrome, Edge, Safari */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #676a73; /* Slightly lighter color for the track */
}

::-webkit-scrollbar-thumb {
  background: #494c55; /* Use the darker color for the thumb */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(254, 121, 0, 0.6); /* Orange color when hovered */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #494c55 #676a73;
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
  .container {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 75%;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 80%;
  }
}
