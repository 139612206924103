.container {
  margin: 20px;
  background: #1c1c1c;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.loading {
  font-size: 24px;
  color: #ffab00;
  text-align: center;
  margin-top: 50px;
}

.topSection {
  display: flex;
  margin-bottom: 40px;
}

.imageSection {
  width: 35%;
  margin-right: 30px;
}

.profileImage {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.infoSection {
  flex: 1;
}

.name {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #ffab00;
}

.biography {
  font-size: 18px;
  margin-bottom: 15px;
}

.infoLabel {
  font-weight: bold;
  color: #ffab00;
}

.movieTitle {
  font-size: 24px;
  margin-bottom: 20px;
}

.movieList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.movieItem {
  width: calc(12.5% - 10px);
}

.movieLink {
  color: #fff;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.2s;
}

.movieLink:hover {
  transform: scale(1.02);
}

.creditTitle {
  font-size: 18px;
  margin: 10px 0;
}

.creditImage {
  width: 100%;
  border-radius: 5px;
  transition: opacity 0.2s;
}

.creditImage:hover {
  opacity: 0.8;
}

.character {
  font-size: 14px;
  margin-bottom: 5px;
}

.vote {
  font-size: 16px;
  font-weight: bold;
  color: #ffab00;
  background-color: #1c1c1c;
  padding: 5px 10px;
  border-radius: 20px;
  margin-bottom: 5px;
}
